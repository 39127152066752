import * as Yup from "yup";
import i18n from '../i18n'
export const signUpValidationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t("VALIDATION.NAME_REQUIRED")),
  email: Yup.string()
    .email(i18n.t("VALIDATION.EMAIL_INVALID"))
    .required(i18n.t("VALIDATION.EMAIL_REQUIRED")),
  countryCode: Yup.string().required(i18n.t("VALIDATION.PHONE_REQUIRED")),
  mobile: Yup.string()
    .required(i18n.t("VALIDATION.PHONE_REQUIRED"))
    .matches(/^[0-9]+$/, i18n.t("VALIDATION.PHONE_INVALID"))
    .min(7, i18n.t("VALIDATION.PHONE_MIN", { min: 7 }))
    .max(11, i18n.t("VALIDATION.PHONE_MAX", { max: 11 })),
  password: Yup.string()
    .min(6, i18n.t("VALIDATION.PASSWORD_MIN", { min: 6 }))
    .test(
      "not-same-as-email",
      i18n.t("VALIDATION.PASSWORD_SAME_AS_EMAIL"),
      function (value) {
        const { email } = this.parent;
        return !(value && email && value.toLowerCase() === email.toLowerCase());
      }
    )
    .required(i18n.t("VALIDATION.PASSWORD_REQUIRED")),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], i18n.t("VALIDATION.CONFIRM_PASSWORD_MATCH"))
    .required(i18n.t("VALIDATION.CONFIRM_PASSWORD_REQUIRED"))
});
export const deleteAccountSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t("VALIDATION.EMAIL_INVALID"))
    .required(i18n.t("VALIDATION.EMAIL_REQUIRED")),
 
});



export const loginValidationSchema = Yup.object().shape({
  countryCode: Yup.string().required("Phone number is required"),
  mobile: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number must be a valid number") 
    .min(7, "Phone number must be at least 7 digits") 
    .max(11, "Phone number must be at most 11 digits"), 
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export const forgotValidationSchema = Yup.object().shape({
  countryCode: Yup.string().required("Phone number is required"),
  mobile: Yup.string()
  .required("Phone number is required")
  .matches(/^[0-9]+$/, "Phone number must be a valid number") 
  .min(7, "Phone number must be at least 7 digits") 
  .max(11, "Phone number must be at most 11 digits"), 
});
