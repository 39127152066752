import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectadditionalDetails,
  selectCategories,
  selectCategoryError,
  selectSpecifications,
  setadditionalDetails,
  setCategories,
  setSpecifications,
} from "../store/categorySlice";
import { categoryService } from "../services/category.api";

const useCategory = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const specifications = useSelector(selectSpecifications);
  const additionalDetails = useSelector(selectadditionalDetails);
  const error = useSelector(selectCategoryError);
  const [loading, setLoading] = useState(false);

  const fetchCategories = async (limit) => {
    setLoading(true);
    const data = await categoryService.fetchCategories(limit);
    if (data.success) {
      dispatch(setCategories(data));
    }
    setLoading(false);
    return data;
  };

  const fetchSubCategories = async (id) => {
    const data = await categoryService.fetchSubCategories(id);
    return data;
  };

  const getSpecificationsList = async () => {
    setLoading(true);
    const data = await categoryService.fetchSpecificationsList();
    if (data.success) {
      dispatch(setSpecifications(data));
    }
    setLoading(false);
    return data;
  };

  const getAdditionalDetails = async () => {
    setLoading(true);
    const data = await categoryService.fetchAdditionalDetails();
    if (data.success) {
      dispatch(setadditionalDetails(data));
    }
    setLoading(false);
    return data;
  };

  const fetchCategoryListForDropdown = async () => {
    const data = await categoryService.fetchCategoryListForDropdown();
    return data;
  };

  useEffect(() => {
    fetchCategories(1000);
  }, []);

  return {
    categories,
    getSpecificationsList,
    getAdditionalDetails,
    fetchCategoryListForDropdown,
    fetchSubCategories,
    specifications,
    additionalDetails,
    loading,
    error,
  };
};

export default useCategory;
