import api from "../utils/API";

// Base URL for your API
export const categoryService = {
  fetchCategories: async (limit=10) => {
    const response = await api.get("/category",{params:{limit}});
    return response.data;
  },

  fetchSubCategories: async (categoryId) => {
    const response = await api.get(`/category/sub-categories/${categoryId}`);
    return response.data;
  },
  fetchCategoryDetails: async (id) => {
    const response = await api.get(`/category/${id}`);
    return response.data;
  },
  fetchSpecificationsList: async (id) => {
    const response = await api.get("/specification");
    return response.data;
  },
  fetchAdditionalDetails: async (id) => {
    const response = await api.get("/additional-details");
    return response.data;
  },
  fetchCategoryListForDropdown: async()=>{
    const response = await api.get("/category/getCategory/list");
    return response.data;
  }
};
