import React from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  IconButton,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useConfig from "../../hooks/useConfig";
import getLocalizedValue from "../../utils/getLocalizedValue";
import parse from 'html-react-parser'

const FooterLinks = [
  {
    Label: "HOME",
    path: "/",
  },
  {
    Label: "POST_ADS",
    path: "create",
  },
  {
    Label: "CATEGORIES",
    path: "#category-list",
  },
  {
    Label: "ABOUT_US",
    path: "/about",
  },
  {
    Label: "MY_ACCOUNT",
    path: "/account",
  },
  {
    Label: "CONTACT_US",
    path: "#contact-us",
  },
  {
    Label: "FAQ",
    path: "/faq",
  },
  {
    Label: "TERMS_AND_CONDITIONS",
    path: "/terms-and-conditions",
  },
  {
    Label: "PRIVACY_POLICY",
    path: "/privacy-policy",
  },
];

const Icons = {
  facebook: <FacebookIcon />,
  twitter: <TwitterIcon />,
  instagram: <InstagramIcon />,
  linkedIn: <LinkedInIcon />,
};

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { config } = useConfig();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    if (path.startsWith("#")) {
      // Navigate to "/" and scroll to the specific section
      navigate("/", { replace: true });
      setTimeout(() => {
        const target = document.querySelector(path);
        if (target) {
          target.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Wait for navigation
    } else if(path.startsWith("/")) {
      navigate(path);
    }else{
      window.location.href = `/${path}`
    }
  }

  return (
    <Box
      sx={{ backgroundColor: theme.palette.primary.main, color: "#fff", py: 6 }}
    >
      <Container maxWidth="lg">
        {/* Logo */}
        <Box
          width="100%"
          display="flex"
          justifyItems="center"
          alignItems="center"
          mb={4}
        >
          <img
            src={config.footerLogo || '/logo-white.png'}
            alt="Logo"
            style={{ width: "100px", height: "100px", margin: "0 auto" , cursor:'pointer' }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/placeholder-images-image_large.webp";
            }}
            onClick={()=>{
              navigate('/')
            }}
          />
        </Box>

        {/* Navigation Links */}
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ maxWidth: "800px" }}>
            <Box
              display="flex"
              flexWrap={isMobile ? "nowrap" : "wrap"}
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="center"
              alignItems="center"
            >
              {FooterLinks?.map((item, index) => (
                 <Box
                 key={index}
                 sx={{
                   display: "flex",
                   marginBottom: isMobile ? "0px" : "20px",
                   cursor: "pointer",
                 }}
                 onClick={() => handleNavigation(item.path)}
               >
                 <Typography
                   variant="customStyle"
                   sx={{ mx: 1, my: isMobile ? 1 : 0, color: "#FFFFFF" }}
                 >
                   {t(item.Label)}
                 </Typography>
                 {index < 8 && (
                   <Divider
                     orientation={isMobile ? "horizontal" : "vertical"}
                     sx={{
                       height: isMobile ? "1px" : "24px",
                       width: isMobile ? "100%" : "auto",
                       borderColor: "#fff",
                       mx: 2,
                       display: isMobile ? "none" : "block",
                     }}
                   />
                 )}
               </Box>
              ))}
            </Box>
          </Grid>
        </Grid>

        {/* Social Media Icons */}
        {/* <Box textAlign="center" mt={4}>
          {config?.socialMediaAccounts?.map((item, index) => {
            return (
              <a href={`https:${item.url}`} key={index}  target="_blank" rel="noreferrer">
                <IconButton  sx={{ color: "#fff" }}>
                  {Icons[item.platformLogo]}
                </IconButton>
              </a>
            );
          })}
        </Box> */}

        <Box sx={{display:'flex',justifyContent:'center',gap:2}} mt={4}>
          {config?.socialMediaAccounts?.map(item=>{
            return (<Box component='a' href={item.url} target="_blank"> <img src={item?.icon} width='30px' alt="sold" /></Box>)
          })}
        </Box>

        {/* Copyright */}
        <Box textAlign="center">
          <Typography variant="body2">
            {
                   parse(getLocalizedValue(config.footerText) || '<h2></h2>')
            }
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
