import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Link,
  Grid,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { useFormik } from "formik";
import { deleteAccountSchema } from "../../services/auth.validation";
import OtpVerification from "../common/OtpVerification";

const DeleteAccount = () => {
  const { t } = useTranslation();
  const { accountDelete, loading, accountDeleteVerifyOTP } = useAuth();
  const [DeleteAccountErrorMessage, setDeleteAccountErrorMessage] = useState("");
  const [isDeleteAccountSuccessful, setIsDeleteAccountSuccessful] = useState(false); // Track DeleteAccount success

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: deleteAccountSchema,
    onSubmit: async (values) => {
      const response = await accountDelete(values);
      if (response.success) {
        setIsDeleteAccountSuccessful(true);
        setDeleteAccountErrorMessage("");
      } else {
        setIsDeleteAccountSuccessful(false);
        setDeleteAccountErrorMessage(response.errorMessage);
      }
    },
  });

  return (
    <Container maxWidth="xs" sx={{ padding: 4 }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "70vh" }}
      >
        {!isDeleteAccountSuccessful ? (
          // Sign-Up Form
          <>
            <Typography
              variant="h4"
              sx={{ color: "primary.main", mb: 4, fontWeight: "bold" }}
            >
              {t("DELETE_MY_ACCOUNT")}
            </Typography>
            <Grid style={{ width: "100%" }}>
              {DeleteAccountErrorMessage ? (
                <Alert
                  severity="error"
                  style={{ width: "100%", marginBottom: "20px" }}
                >
                  {DeleteAccountErrorMessage}
                </Alert>
              ) : (
                <></>
              )}
            </Grid>
            <DeleteAccountForm
              formik={formik}
              t={t}
              loading={loading}
            />
          </>
        ) : (
          // OTP Input Form
          <OtpVerification
            email={formik.values.email}
            t={t}
            verifyOTP={accountDeleteVerifyOTP}
          />
        )}
      </Box>
    </Container>
  );
};

const DeleteAccountForm = ({ formik, t, loading }) => {
  const { i18n } = useTranslation();

 

  const isRTL = i18n.dir() === "rtl"; // Check if current language is RTL

  
  return (
    <form onSubmit={formik.handleSubmit} >
      

      {/* Email Input */}
      <Box display="flex" justifyContent="flex-start" width="100%">
        <Typography variant="body1" fontWeight="bold">
          {t("EMAIL")}
        </Typography>
      </Box>
      <TextField
        name="email"
        size="small"
        variant="outlined"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        fullWidth
        dir="ltr"
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root": {
            backgroundColor: "background.white",
            textAlign: isRTL ? "right" : "left", // Align text based on language
          },
          "& .MuiFormHelperText-root": {
            textAlign: isRTL ? "right" : "left", // Helper text follows language direction
            direction: isRTL ? "rtl" : "ltr",
          },
        }}
        InputProps={{
          sx: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          inputMode: "numeric", // Suggest a numeric keyboard for mobile devices
          pattern: "[0-9]*", // Regex pattern to restrict input to numbers
        }}
      />

      
      {/* Sign Up Button */}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mb: 2 }}
        onClick={formik.handleSubmit}
        disabled={loading}
      >
        {t("DELETE_MY_ACCOUNT")}
      </Button>

      {/* Terms and Conditions */}
      <Typography variant="body2" align="center" sx={{ mb: 2 }}>
        {t("AGREE_TERMS")}
        <Link href="/terms-and-conditions" color="primary">
          {t("TERMS_AND_CONDITIONS")}
        </Link>{" "}
        {t("AND")}{" "}
        <Link href="/privacy-policy" color="primary">
          {t("PRIVACY_POLICY")}
        </Link>
        .
      </Typography>

      {/* Already have an account */}
      <Typography variant="body2" align="center">
        {t("ALREADY_HAVE_ACCOUNT")}{" "}
        <Link href="/login" color="primary">
          {t("LOGIN_HERE")}
        </Link>
      </Typography>
    </form>
  );
};


export default DeleteAccount