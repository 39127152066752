import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const OtpVerification = ({ t, verifyOTP, email, forgotPassword = false, verifyUser = false }) => {
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Array to hold each OTP digit
  const [otpError, setOtpError] = useState(""); // Error message state
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(180); // 3-minute timer
  const { resendOTP } = useAuth();

  // Handle OTP Resend timer
  useEffect(() => {
    if (resendDisabled) {
      const interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
        if (timer === 0) {
          setResendDisabled(false);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [resendDisabled, timer]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Automatically move to the next input box if a value is entered
      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }

      // Clear the error if the user is typing
      setOtpError("");
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // Move to the previous input box if backspace is pressed and current box is empty
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleResendOtp = () => {
    resendOTP({ email });
    setResendDisabled(true);
    setTimer(180); // Reset timer to 3 minutes
  };

  const handleSubmitOtp = async () => {
    const fullOtp = otp.join("");
    if (fullOtp.length !== 6) {
      setOtpError(t("INVALID_OTP")); // Show error if OTP is not 6 digits
    } else {
      // Logic to verify the OTP goes here
      let responce = await verifyOTP({
        otp: fullOtp,
        email,
        forgotPassword: forgotPassword,
        verifyUser: verifyUser
      });
      if (responce.success) {
        if (forgotPassword) {
          navigate("/reset-password", { state: { email: email, forgotPassword: true } });
        } else {
          navigate("/");
        }
        setOtpError("");
      } else {
        setOtpError(responce.errorMessage);
      }
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "70vh" }}
      dir="ltr"
    >
      <Typography
        variant="h4"
        sx={{ color: "primary.main", mb: 4, fontWeight: "bold" }}
      >
        {t("ENTER_OTP")}
      </Typography>

      <Grid container spacing={1} justifyContent="center" sx={{ mb: 2 }}>
        {otp.map((digit, index) => (
          <Grid item xs={2} key={index}>
            <TextField
              variant="outlined"
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              inputProps={{
                maxLength: 1,
                style: { textAlign: "center", fontSize: "1rem" },
              }}
              inputRef={(el) => (inputRefs.current[index] = el)} // Store the ref
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "background.white",
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
      {/* Error message for invalid OTP */}
      {otpError && (
        <Typography variant="caption" color="error" sx={{ mb: 2 }}>
          {otpError}
        </Typography>
      )}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mb: 2 }}
        onClick={handleSubmitOtp} // Joins the array into a single string for verification
      >
        {t("VERIFY_OTP")}
      </Button>

      <Button
        variant="outlined"
        color="primary"
        fullWidth
        disabled={resendDisabled}
        onClick={handleResendOtp}
      >
        {t("RESEND_OTP")}{" "}
        {resendDisabled &&
          `(${Math.floor(timer / 60)}:${(timer % 60)
            .toString()
            .padStart(2, "0")})`}
      </Button>
    </Box>
  );
};

export default OtpVerification;
